<template>
  <div class="tw-mx-auto">
    <template v-if="filter === 'all' && !initializing && !loading">
      <SectionsTab :options="options" :sections="allSections">
        <template #actions>
          <slot name="actions" />
        </template>
      </SectionsTab>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import useConfirmLeave from "@composables/confirm_leave.js";
import { GET_ALL_SECTIONS,
  SET_ALL_SECTIONS,
  GET_SLUGS,
  SET_SECTION_TYPE,
  SET_SECTION_SLUG,
  GET_LOADING as SECTIONS_LOADING } from "../../../vuex-store/modules/sections.js";
import { FETCH_STATES_FOR_SECTION, GET_LOADING as STATES_LOADING } from "../../../vuex-store/modules/states.js";
import { VALIDATE, FETCH_LINKED_VALIDATIONS } from "../../../vuex-store/modules/validations.js";
import { GET_USER_SLUG,
  GET_USER_NAME,
  SET_USER } from "../../../vuex-store/modules/users.js";
import { FETCH_USERS,
  FETCH_ASSIGNMENTS,
  GET_LOADING as ASSIGNMENTS_LOADING } from "../../../vuex-store/modules/assignments.js";
import { FETCH_CLAIM_FEEDBACK,
  SET_CLAIM_PERIOD_SLUG,
  GET_LOADING as FEEDBACKS_LOADING,
  GET_WARNING_PROMPT } from "../../../vuex-store/modules/feedbacks.js";
import { FETCH_AUDIT_HISTORY } from "../../../vuex-store/modules/audit_history.js";
import { FETCH_SECTION_REVIEWS } from "../../../vuex-store/modules/reviews.js";
import { HAS_UNSAVED_CHANGES } from "../../../vuex-store/modules/fields.js";

import SectionsTab from "./sections_tab.vue";
import { toValidateFields } from "../../../shared/fields.js";

export default {
  components: {
    SectionsTab
  },
  props: {
    sectionType: {
      type: String,
      validator: value => {
        const validSections = ["Status", "Project", "Challenge", "Grant"];

        return validSections.indexOf(value) >= 0;
      },
      required: false
    },
    sectionSlug: {
      type: String,
      required: false
    },
    claimPeriodSlug: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: () => ({})
    },
    newProject: {
      type: Boolean,
      default: false
    },
    oldProject: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    useConfirmLeave();
  },
  data() {
    return {
      filter: "all",
      initializing: true,
      dataExpired: false
    };
  },
  channels: {
    FieldEditChannel: {
      connected() {
      },
      received(data) {
        if (!this.dataExpired && data?.response?.content?.field.includes(`${this.sectionType}:${this.sectionSlug}`)) {
          this.dataExpired = true;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      allSections: `sections/${GET_ALL_SECTIONS}`,
      getUserSlug: `users/${GET_USER_SLUG}`,
      getUserName: `users/${GET_USER_NAME}`,
      statesLoading: `states/${STATES_LOADING}`,
      sectionsLoading: `sections/${SECTIONS_LOADING}`,
      assignmentsLoading: `assignments/${ASSIGNMENTS_LOADING}`,
      feedbacksLoading: `feedbacks/${FEEDBACKS_LOADING}`,
      hasUnsavedChanges: `fields/${HAS_UNSAVED_CHANGES}`
    }),
    isEmpowerrd() {
      const userRole = this.options.user_role;
      return userRole == "emp_reviewer" || userRole == "emp_admin";
    },
    isReviewer() {
      const userRole = this.options.user_role;
      return userRole == "emp_reviewer";
    },
    loading() {
      return this.statesLoading || this.sectionsLoading || this.assignmentsLoading || this.feedbacksLoading;
    }
  },
  created() {
    this.$cable.subscribe({ channel: "FieldEditChannel" });
    this.unsubscribe = this.$store.subscribe((mutation, _state) => {
      if (mutation?.type === "fields/SET_FIELD_TO_WRITE") {
        this.$cable.perform({
          channel: "FieldEditChannel",
          action: "send_message",
          data: {
            content: {
              user: {
                name: this.getUserName,
                slug: this.getUserSlug
              },
              field: mutation.payload
            }
          }
        });
      }
    });
  },
  mounted() {
    const onPageLoadPromises = [
      this.setUser(),
      this.setSectionSlug(this.sectionSlug),
      this.setSectionType(this.sectionType),
      this.setClaimPeriodSlug(this.claimPeriodSlug)
    ];
    if (!this.isReviewer) {
      onPageLoadPromises.push(this.fetchUsers({ sectionSlug: this.sectionSlug, sectionType: this.sectionType }));
    }
    Promise.all(onPageLoadPromises).then(() => {
      this.initialize();
    });

    this.unwatchUserSlug = this.$store.watch(
      (_state, getters) => getters[`sections/${GET_SLUGS}`],
      (newValue, oldValue) => {
        if (oldValue !== null) {
          this.fetchAssignments(newValue);
        }
      }
    );

    this.track("Section Opened", {
      sectionSlug: this.sectionSlug,
      sectionType: this.sectionType
    });

    window.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  beforeUnmount() {
    this.unwatchUserSlug();
    this.unsubscribe();
    window.removeEventListener("visibilitychange");
  },
  methods: {
    ...mapActions({
      setAllSections: `sections/${SET_ALL_SECTIONS}`,
      setUser: `users/${SET_USER}`,
      fetchStatesForSection: `states/${FETCH_STATES_FOR_SECTION}`,
      fetchAssignments: `assignments/${FETCH_ASSIGNMENTS}`,
      fetchClaimFeedback: `feedbacks/${FETCH_CLAIM_FEEDBACK}`,
      fetchAuditHistory: `audit_history/${FETCH_AUDIT_HISTORY}`,
      fetchSectionReviews: `reviews/${FETCH_SECTION_REVIEWS}`,
      getWarningPrompt: `feedbacks/${GET_WARNING_PROMPT}`,
      validate: `validations/${VALIDATE}`,
      validateLinkedSections: `validations/${FETCH_LINKED_VALIDATIONS}`,
      fetchUsers: `assignments/${FETCH_USERS}`
    }),
    ...mapMutations({
      setClaimPeriodSlug: `feedbacks/${SET_CLAIM_PERIOD_SLUG}`,
      setSectionSlug: `sections/${SET_SECTION_SLUG}`,
      setSectionType: `sections/${SET_SECTION_TYPE}`
    }),
    initialize() {
      this.initializing = true;
      const promises = [
        this.setAllSections({
          sectionType: this.sectionType,
          sectionSlug: this.sectionSlug,
          newProject: this.newProject,
          oldProject: this.oldProject
        }).then(section => {
          this.validate([section.type, section.slug, toValidateFields(section.fields)]);
          this.validateLinkedSections({ sectionSlug: section.slug, sectionType: section.type, newProject: this.newProject });
        }),
        this.fetchStatesForSection({
          sectionType: this.sectionType,
          sectionSlug: this.sectionSlug
        }),
        this.fetchAssignments([`${this.sectionType}:${this.sectionSlug}`]),
        this.fetchClaimFeedback({ claimPeriodSlug: this.claimPeriodSlug }),
        this.getWarningPrompt({ sectionType: this.sectionType, sectionSlug: this.sectionSlug })
      ];

      if (this.isEmpowerrd) {
        promises.push(
          this.fetchAuditHistory({
            sectionType: this.sectionType,
            sectionSlug: this.sectionSlug
          }),
          this.fetchSectionReviews({
            sectionType: this.sectionType,
            sectionSlug: this.sectionSlug
          })
        );
      }
      Promise.all(promises).then(() => {
        this.initializing = false;
        this.dataExpired = false;
      });
    },
    handleVisibilityChange() {
      if (!document.hidden && !this.hasUnsavedChanges && this.dataExpired && !this.loading && !this.initializing) {
        this.initialize();
      }
    }
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
