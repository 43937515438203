<template>
  <div class="tw-w-full">
    <div
      v-if="!nestedReveal"
      class="tw-mb-2"
    >
      <div :field-key="field.label" :group-index="groupIndex" class="tw-flex tw-items-stretch tw-gap-2 tw-justify-between">
        <div class="tw-items-center tw-flex">
          <label :for="field.label" class="tw-block tw-text-large tw-font-medium tw-mb-0">
            <div class="tw-inline tw-space-x-3">
              <span>
                {{ field.title }}
                <span v-if="fieldRequired(sectionType, field.label)">
                  <tooltip content="Required">
                    <span class="tw-text-red-500 tw-text-base">*</span>
                  </tooltip>
                </span>
              </span>
              <state-wrapper component="SectionKnowledgeCentre">
                <KnowledgeCentreTriggerIcon
                  v-if="field.knowledge_centre"
                  :question="field.label"
                  :section="sectionType"
                  :icon="true"
                />
              </state-wrapper>
              <state-wrapper v-if="!basicForm" component="AuditHistoryTrigger">
                <AuditHistoryTrigger
                  :field="field"
                  :section-type="sectionType"
                  :section-slug="sectionSlug"
                />
              </state-wrapper>
              <state-wrapper v-if="!basicForm" component="PreviousResolvedFeedbackIcon">
                <PreviousResolvedFeedbackIcon
                  :section-type="sectionType"
                  :section-slug="sectionSlug"
                  :col-ref="field.label"
                />
              </state-wrapper>
              <saving-spin-indicator
                v-if="
                  ['MultiSelect', 'RadioSelect', 'YesNoRadio', 'NestedSelect', 'DropdownSelect'].includes(field.type) ||
                    (basicForm && field.type ==='LargeText')
                "
                :section-type="sectionType"
                :section-slug="sectionSlug"
                :fields="[field.label]"
              />
            </div>
          </label>
        </div>
        <div class="tw-text-right tw-h-full tw-flex-shrink-0">
          <div v-if="!basicForm" :class="[field.type === 'LargeText' ? 'tw-flex-row' : 'tw-flex-col' ,'tw-flex tw-gap-x-2 tw-items-end']">
            <span v-if="canAssignQuestion">
              <state-wrapper component="AssignIcon">
                <AssignIcon
                  :fields="fields"
                  :section-type="sectionType"
                  :section-slug="sectionSlug"
                  assign-icon-type="FieldAssign"
                  :col-ref="field.label"
                />
              </state-wrapper>
            </span>
            <state-wrapper component="CompleteIcon">
              <CompleteIcon
                :fields="fields"
                :section-type="sectionType"
                :section-slug="sectionSlug"
                complete-icon-type="FieldComplete"
              />
            </state-wrapper>
          </div>
        </div>
      </div>
      <state-wrapper component="field_blurb">
        <p
          v-show="field.field_blurb"
          class="tw-text-xs tw-text-gray-500 tw-pr-6 tw-mt-1 tw-mb-0"
          v-html="field.field_blurb"
        />
      </state-wrapper>
    </div>

    <div>
      <div>
        <state-wrapper
          v-slot="{ editable }"
          :component="field.type"
        >
          <component
            :is="field.type"
            :editable="editable"
            :field="field"
            :section-type="sectionType"
            :section-slug="sectionSlug"
            :is-reviewer="isReviewer"
            :basic-form="basicForm"
          />
        </state-wrapper>
        <state-wrapper component="DebouncedSave">
          <DebouncedSave
            :field="field"
            :section-type="sectionType"
            :section-slug="sectionSlug"
            :debounce-delay="field.type == 'LargeText' ? 60000 : 1000"
          />
        </state-wrapper>
        <span v-if="field.type == 'LargeText'">
          <state-wrapper component="SaveButton">
            <SaveButton
              :field="field"
              :section-type="sectionType"
              :section-slug="sectionSlug"
            />
          </state-wrapper>
        </span>
      </div>
      <state-wrapper v-if="field.can_leave_feedback" component="Feedback">
        <Feedback
          :field="field"
          :section-type="sectionType"
          :section-slug="sectionSlug"
        />
      </state-wrapper>
      <div>
        <IsBeingEdited
          v-if="false"
          :section-type="sectionType"
          :section-slug="sectionSlug"
          :section-field-name="field.label"
        />
      </div>
      <state-wrapper component="ValidationMessage">
        <ValidationMessage
          :section-type="sectionType"
          :section-slug="sectionSlug"
          :field="field"
        />
      </state-wrapper>
      <state-wrapper
        v-slot="{ editable }"
        component="LeaveNarrativeFeedback"
      >
        <LeaveNarrativeFeedback
          v-if="field.can_leave_feedback"
          :editable="editable"
          :section-type="sectionType"
          :section-slug="sectionSlug"
          :field="field"
        />
      </state-wrapper>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { GET_FIELD_REQUIRED } from "@store/sections.js";
import { SET_FIELD_TO_WRITE } from "@store/fields.js";
import { GET_FEEDBACKS_FOR_FIELD } from "@store/feedbacks.js";
import { GET_USER_TYPE } from "@store/states.js";
import { FIELD_STATE } from "./shared/field_state.js";

import SmallText from "./fields/small_text.vue";
import LargeText from "./fields/large_text.vue";
import YesNoRadio from "./fields/yes_no_radio.vue";
import MultiSelect from "./fields/multi_select.vue";
import RadioSelect from "./fields/radio_select.vue";
import Date from "./fields/date.vue";
import Number from "./fields/number.vue";
import Currency from "./fields/currency.vue";
import Feedback from "./feedback.vue";
import AssignIcon from "./assign_icon.vue";
import CompleteIcon from "./complete_icon.vue";
import KnowledgeCentreTriggerIcon from "../../generic/knowledge_centre_trigger_icon.vue";
import IsBeingEdited from "./is_being_edited.vue";
import ValidationMessage from "./validation_message.vue";
import LeaveNarrativeFeedback from "./leave_narrative_feedback.vue";
import AuditHistoryTrigger from "./audit_history_trigger.vue";
import PreviousResolvedFeedbackIcon from "../../generic/previous_resolved_feedback_icon.vue";
import NestedSelect from "./fields/nested_select.vue";
import DropdownSelect from "./fields/dropdown_select.vue";
import RadioButtonRevealField from "./radio_button_reveal_field.vue";

import SaveButton from "./save_buttons/save_button.vue";
import DebouncedSave from "./save_buttons/debounced_save.vue";

export default {
  components: {
    SmallText,
    LargeText,
    YesNoRadio,
    Feedback,
    IsBeingEdited,
    KnowledgeCentreTriggerIcon,
    AssignIcon,
    CompleteIcon,
    MultiSelect,
    RadioSelect,
    Date,
    ValidationMessage,
    LeaveNarrativeFeedback,
    SaveButton,
    DebouncedSave,
    Number,
    Currency,
    PreviousResolvedFeedbackIcon,
    AuditHistoryTrigger,
    NestedSelect,
    DropdownSelect,
    RadioButtonRevealField
  },
  props: {
    sectionType: {
      type: String,
      validator: value => {
        const validSections = ["Project", "Challenge", "ClaimPeriod", "Status", "FinanceDocument", "Grant", "RdProject"];

        return validSections.indexOf(value) >= 0;
      }
    },
    sectionSlug: {
      type: String,
      required: true
    },
    field: {
      type: Object,
      required: true,
      validator: obj => {
        const valid_field_types = [
          "SmallText",
          "LargeText",
          "YesNoRadio",
          "MultiSelect",
          "RadioSelect",
          "Date",
          "Number",
          "Currency",
          "NestedSelect",
          "DropdownSelect",
          "RadioButtonRevealField"
        ];

        return valid_field_types.indexOf(obj.type) >= 0;
      }
    },
    userSlug: {
      type: String,
      default: null
    },
    groupIndex: {
      required: false
    },
    basicForm: {
      type: Boolean,
      default: false
    },
    nestedReveal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getFeedbackFields: `feedbacks/${GET_FEEDBACKS_FOR_FIELD}`,
      userType: `states/${GET_USER_TYPE}`,
      fieldRequired: `sections/${GET_FIELD_REQUIRED}`
    }),
    fields() {
      return [this.field];
    },
    canAssignQuestion() {
      return this.field?.can_assign;
    },
    key() {
      return `${this.sectionType}:${this.sectionSlug}:${this.field.label}`;
    },
    isReviewer() {
      return this.userType == "reviewer";
    }
  },
  watch: {
    "field.value": function (_val) {
      if (this.field.state < FIELD_STATE.EDITED) {
        this.setFieldToWrite(this.key);
        this.field.state = FIELD_STATE.EDITED;
      }
    },
    "field.state": function (newVal, oldVal) {
      if (newVal !== oldVal && newVal === FIELD_STATE.SAVED) {
        this.setFieldToWrite(this.key);
      }
    }
  },
  methods: {
    ...mapMutations({
      setFieldToWrite: `fields/${SET_FIELD_TO_WRITE}`
    })
  }
};
</script>
