<template>
  <div v-if="lastRefreshed" class="tw-flex tw-justify-end tw-items-center tw-text-sm tw-text-gray-500 tw-italic">
    <span>Last refreshed: {{ formatTimeDistance(lastRefreshed, { unit: 'minute' }) }} ago</span>
  </div>
  <div>
    <DataTable
      :data="flattenData"
      :columns="tableColumns"
      enable-search
      :paginator-rows="20"
      :filter-fields="['name']"
      :is-loading="isLoading"
      :expanded-component="CheckResultComponent"
      :enable-column-selection="true"
    />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import DataTable from "@generic/data_table.vue";
import CheckResultComponent from "@components/checks/check_result_component.vue";
import { formatDate, formatTimeDistance } from "@shared/helpers.js";
import { get } from "@shared/http.js";

const isLoading = ref(true);
const errorMessage = ref("");
const checksDashboardData = ref([]);
const lastRefreshed = ref(null);

async function getChecksDashboardData() {
  const res = await get("/api/v1/checks/dashboard_data");

  if (res.error) {
    errorMessage.value = "There was an error fetching the checks dashboard data.";
    return;
  }
  checksDashboardData.value = res.instances;
  lastRefreshed.value = res.last_refreshed;
  isLoading.value = false
}

getChecksDashboardData();

const flattenData = computed(() => checksDashboardData.value.map(data => ({
  ...data,
  ...{ checkResult: data.ready_for_client_approval },
  ...Object.fromEntries(
    ["ready_for_client_approval", "can_be_pre_prepped"].map(check => [
      [`${check}_fraction`, data[check].success_count / data[check].total_count],
      [`${check}_label`, `${data[check].success_count} / ${data[check].total_count}`],
      [`${check}_link`, data[check].breakdown_link]
    ]).flat()
  )
})));

const tableColumns = [
  {
    header: "Claim Period",
    field: "name",
    sortable: true,
    link: "claim_link"
  },
  {
    header: "Claim Readiness",
    field: "ready_for_client_approval_label",
    sortable: true,
    sortField: "ready_for_client_approval_fraction",
    link: "ready_for_client_approval_link"
  },
  {
    header: "EMC",
    field: "emc",
    sortable: true,
    sortField: "emc",
    selectable: true,
    filter: true
  },
  {
    header: "Claim Manager",
    field: "claim_manager",
    sortable: true,
    filter: true,
    selectable: true
  },
  {
    header: "User Last Seen",
    field: "last_seen",
    formatter: formatDate,
    formatterArguments: ["H:mm d/M/yy"],
    sortable: true,
    selectable: true
  },
  {
    header: "Accounts Filed",
    field: "accounts_filed",
    sortable: true,
    filter: true,
    selectable: true
  },
  {
    header: "Can be Pre-Prepped",
    field: "can_be_pre_prepped_label",
    sortable: true,
    sortField: "can_be_pre_prepped_fraction",
    selectable: true,
    link: "can_be_pre_prepped_link"
  },
  {
    header: "Submission Status",
    field: "submission_status",
    sortable: true,
    link: "submission_link",
    selectable: true,
    filter: true
  }
];

</script>
