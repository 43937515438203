<template>
  <div>
    <PrimeDataTable
      ref="dt"
      v-model:filters="filters"
      v-model:editingRows="editingRows"
      v-model:expandedRows="expandedRows"
      data-key="name"
      :export-filename="exportFileName"
      scrollable
      :paginator="!errorMessage && !!paginatorRows"
      :rows="paginatorRows"
      :rows-per-page-options="paginatorDropdownIncrements"
      :value="data"
      :row-class="rowData => `${rowData.error ? 'tw-text-red-700 tw-font-bold' : 'tw-text-black'} ${rowClasses} tw-font-semibold ${useRowClick ? 'hover:!tw-bg-disable-grey-100' : ''}`"
      :global-filter-fields="filterFields ? filterFields : dataColumns.map(col => col.filterField || col.field)"
      filter-display="menu"
      :header-class="'tw-bg-transparent'"
      removable-sort
      :always-show-paginator="data.length > paginatorDropdownIncrements[0]"
      :loading="isLoading"
      :style="`min-height: ${isLoading ? '260px' : ''}`"
      :edit-mode="editMode"
      @cell-edit-complete="event => emit('on-cell-edit-complete', event)"
      @row-edit-save="event => emit('on-row-edit-save', event)"
      @row-click="event => emit('on-row-click', event)"
    >
      <template v-if="tableHeader || enableSearch || enableExport" #header>
        <div class="tw-flex tw-justify-between tw-items-center tw-gap-4 tw-w-full">
          <div class="tw-flex tw-items-center tw-gap-2 tw-flex-1 tw-overflow-hidden">
            <h2 v-if="tableHeader" class="tw-font-bold tw-text-xl tw-text-gray-700">
              {{ tableHeader }}
            </h2>
            <div v-if="enableColumnSelection && !headers" class="tw-max-w-full">
              <MultiSelect :modelValue="selectedColumns" :options="selectableColumns" optionLabel="header" @change="onToggle"
                placeholder="Select Columns" display="chip" />
            </div>
          </div>
          <div v-if="!errorMessage" class="tw-flex-none tw-flex tw-justify-end tw-items-center tw-space-x-5">
            <div v-if="enableSearch" class="tw-flex tw-justify-start tw-items-center tw-space-x-5">
              <Button
                text="Clear"
                :colour="filters['global'].value ? 'confirm' : 'disabled'"
                :class="!filters['global'].value && 'tw-pointer-events-none tw-opacity-50'"
                type="transparent"
                icon="filter"
                @click="filters['global'].value = null"
              />
              <span class="p-input-icon-left">
                <i class="fa fa-search" />
                <input
                  v-model="filters['global'].value"
                  type="text"
                  :class="`${css.forms.input} tw-pl-7 tw-text-black`"
                  placeholder="Search"
                >
              </span>
            </div>
            <div v-if="enableExport" class="tw-text-center hover:tw-text-deep-blue-hover tw-transition tw-delay-50">
              <Button
                text="Export Table"
                icon="far fa-external-link"
                label="Export"
                @click="exportCSV($event)"
              />
            </div>
          </div>
        </div>
      </template>

      <ColumnGroup type="header">
        <Row v-for="(row, row_index) in tableHeaders" :key="row_index">
          <Column v-if="expandedComponent" style="width: 5rem" :header-class="headerClasses()" />
          <Column
            v-for="(col, col_index) in row"
            :key="col_index"
            :field="col.field"
            :frozen="col.frozen || false"
            :colspan="col.colspan || 1"
            :rowspan="col.rowspan || tableHeaders.length"
            :sortable="col.sortable"
            :sort-field="col.sortField || col.field"
            :filter-field="col.filter ? col.field : null"
            :show-filter-match-modes="false"
            :show-clear-button="false"
            :show-apply-button="false"
            :header-class="headerClasses()"
          >
            <template #header>
              <span>{{ col.header }}</span>
              <KnowledgeCentreTriggerIcon
                v-if="col.knowledgeCentreFaq"
                icon
                :faq="col.knowledgeCentreFaq"
              />
            </template>
            <template v-if="col.filter" #filter="{ filterModel, filterCallback }">
              <MultiSelect v-model="filterModel.value" filter @change="filterCallback()" :maxSelectedLabels="1" :options="filterOptions[col.field]" placeholder="Any">
                <template #option="slotProps">
                  <div class="flex items-center gap-2">
                    <span>{{ slotProps.option || "(blank)" }}</span>
                  </div>
                </template>
              </MultiSelect>
            </template>
          </Column>
        </Row>
      </ColumnGroup>
      <Column v-if="expandedComponent" expander style="width: 5rem" />
      <Column
        v-for="(col, index) in columnsToDisplay"
        :key="col.field + '_' + index"
        :field="col.field"
        :body-class="bodyClasses(col)"
        :frozen="col.frozen || false"
        :filter-field="col.filter ? col.field : null"
        :show-filter-match-modes="false"
        :export-header="col.exportHeader? col.exportHeader : col.header"
        :pt=" {
          bodyCell: {
            'exclude-from-click': col.excludeFromClick
          }
        }"
      >
        <template v-if="col.editable" #editor="{ data, field }">
          <div class="tw-flex tw-gap-1 tw-items-center">
            <InputNumber
              v-if="col.fieldType === 'currency'"
              v-model="data[field]"
              mode="currency"
              currency="GBP"
              :min="0"
              :input-class="`${css.forms.input} tw-border tw-text-black tw-px-2`"
            />
            <InputNumber
              v-else-if="col.fieldType === 'percentage'"
              v-model="data[field]"
              suffix="%"
              :input-class="`${css.forms.input} tw-border tw-text-black tw-px-2`"
            />
            <InputText
              v-else
              v-model="data[field]"
              :class="`${css.forms.input} ${hasErrored(data.slug, field) ? '!tw-border-red-700 tw-text-red-700' : 'tw-text-black'} tw-border tw-px-2`"
              @update:model-value="value => emit('on-input-text-update', {field: col.field, slug: data.slug, value})"
            />
            <Tooltip
              v-if="hasErrored(data.slug, field)"
              :content="cellMetadata[data.slug][field].message"
              :offset="[0, 15]"
              show-immediately
            >
              <Icon type="exclamation-triangle" />
            </Tooltip>
          </div>
        </template>

        <template #body="slotProps">
          <component :is="col.component" v-if="col.component" v-bind="slotProps.data[col.field]" />

          <button-component v-else-if="col.link" :link="col.link ? slotProps.data[col.link] : null" :new-tab="true">
            <span :class="[hasErrored(slotProps.data.slug, col.field) ? 'tw-text-red-700' : '', col.link ? 'hover:tw-underline' : '']">
              <template v-if="col.formatter">
                {{ col.formatterArguments ? col.formatter(slotProps.data[col.field], ...col.formatterArguments): col.formatter(slotProps.data[col.field]) }}
              </template>
              <template v-else>
                {{ slotProps.data[col.field] }}
              </template>
            </span>
          </button-component>
          <span v-else :class="[hasErrored(slotProps.data.slug, col.field) ? 'tw-text-red-700' : '', col.link ? 'hover:tw-underline' : '']">
            <template v-if="col.formatter">
              {{ col.formatterArguments ? col.formatter(slotProps.data[col.field], ...col.formatterArguments): col.formatter(slotProps.data[col.field]) }}
            </template>
            <template v-else>
              {{ slotProps.data[col.field] }}
            </template>
          </span>

          <DataTableEditIcons
            v-if="col.editable"
            :edit-mode="editMode"
            :cell-metadata="cellMetadata[slotProps.data.slug] && cellMetadata[slotProps.data.slug][col.field]"
          />
        </template>
      </Column>

      <Column
        v-if="editMode === 'row'"
        row-editor
        :header-class="headerClasses()"
        class="tw-w-24"
      >
        <template #roweditoriniticon>
          <Icon type="pencil" />
        </template>
        <template #roweditorsaveicon>
          <Icon type="check" />
        </template>
        <template #roweditorcancelicon>
          <Icon type="times" />
        </template>
      </Column>

      <ColumnGroup v-if="tableFooters" type="footer">
        <Row v-for="(row, row_index) in tableFooters" :key="row_index">
          <Column
            v-for="(col, col_index) in row"
            :key="col_index"
            :footer="col.footer"
            :colspan="col.colspan || 1"
            footer-class="tw-py-3"
            :footer-style="col.style || 'text-align:center; background-color: #ededed'"
          >
            <template v-if="col.footerComponent" #footer>
              <component :is="col.footerComponent" v-bind="footerProps" :field="col.field" />
            </template>
          </Column>
        </Row>
      </ColumnGroup>

      <template v-if="!isLoading" #empty>
        <p :class="`${errorMessage ? 'tw-text-red-600' : ''} tw-my-4 tw-px-[14px]`">
          {{ errorMessage ? errorMessage : 'No Records Found' }}
        </p>
      </template>

      <template v-if="expandedComponent" #expansion="slotProps">
        <component :is="expandedComponent" v-bind="slotProps.data" />
      </template>

      <template #loading>
        <LoadingSpinner />
      </template>
    </PrimeDataTable>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import PrimeDataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import { FilterMatchMode } from "primevue/api";
import Column from "primevue/column";
import Row from "primevue/row";
import ColumnGroup from "primevue/columngroup";
import KnowledgeCentreTriggerIcon from "@generic/knowledge_centre_trigger_icon.vue";
import Button from "@generic/button_component.vue";
import LoadingSpinner from "@generic/loading_spinner.vue";
import DataTableEditIcons from "@generic/data_table_edit_icons.vue";
import Icon from "@generic/icon.vue";
import Tooltip from "@generic/tooltip.vue";
import MultiSelect from "primevue/multiselect";
import "primevue/resources/themes/rhea/theme.css";
import { css } from "@shared/theme.js";

const editingRows = ref([]);
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});

onMounted(() => {
  dataColumns.value.filter(col => col.filter).forEach(col => {
    filters.value[col.field] = { value: null, matchMode: FilterMatchMode.IN };
  });
});

const props = defineProps({
  data: {
    type: Array
  },
  exportFileName: {
    type: String
  },
  headers: {
    type: Array
  },
  columns: {
    type: Array
  },
  footers: {
    type: Array
  },
  footerProps: {
    type: Object
  },
  paginatorRows: {
    type: Number
  },
  paginatorDropdownIncrements: {
    type: Array,
    default: () => [5, 10, 20, 50, 100]
  },
  enableExport: {
    type: Boolean
  },
  enableSearch: {
    type: Boolean
  },
  altStyling: {
    type: Boolean // grey header rather than blue
  },
  tableHeader: {
    type: String,
    default: ""
  },
  errorMessage: {
    type: String,
    default: ""
  },
  filterFields: {
    type: Array,
    default: null
  },
  isLoading: {
    type: Boolean
  },
  rowClasses: {
    type: String
  },
  useRowClick: {
    type: Boolean
  },
  editMode: {
    type: String
  },
  cellMetadata: {
    type: Object,
    default: () => ({})
  },
  expandedComponent: {
    type: Object
  },
  enableColumnSelection: {
    type: Boolean,
    default: false
  }
});

const dt = ref();

const expandedRows = ref([]);

const exportCSV = () => {
  dt.value.exportCSV();
};

const tableHeaders = computed(() => props.headers || [columnsToDisplay.value]);
const tableFooters = computed(() => {
  if (props.footers) {
    return props.footers;
  } else if (props.columns && props.columns.some(col => col.footerComponent || col.footer)) {
    return [props.columns];
  }
  return [];
});
const dataColumns = computed(() => props.columns || tableHeaders.value.flat().filter(col => col.field));
const selectableColumns = computed(() => dataColumns.value.filter(col => col.selectable));
const permanentColumns = computed(() => dataColumns.value.filter(col => !col.selectable));
const columnsToDisplay = computed(() => [...permanentColumns.value, ...selectedColumns.value]);

const selectedColumns = ref(selectableColumns.value);
const onToggle = val => {
  selectedColumns.value = selectableColumns.value.filter(col => val.value.map(selectedCol => selectedCol.field).includes(col.field));
};

const filterOptions = computed(() => {
  const options = {};
  dataColumns.value.filter(col => col.filter).forEach(col => {
    // If the column has filterOptions, use them
    if (col.filterOptions) {
      options[col.field] = col.filterOptions;
    } else {
      // Otherwise, use the possible values from the column
      options[col.field] = [...new Set(props.data.map(data => data[col.field]))];
    }
  });
  return options;
});

const emit = defineEmits([
  "on-row-click",
  "on-cell-edit-complete",
  "on-row-edit-save",
  "on-input-text-update"
]);

const bodyClasses = col => {
  const alignmentClass = col.alignmentOverride || "tw-text-center";
  const colClasses = col.colClasses || "";
  const editableClass = col.editable ? "tw-cursor-pointer" : "";
  const excludeFromClickClass = col.excludeFromClick ? "tw-cursor-auto" : "";

  return `tw-py-3 tw-h-[60px] tw-px-2 tw-text-[12px] ${alignmentClass} ${colClasses} ${editableClass} ${excludeFromClickClass}`;
};

const headerClasses = () => {
  const baseClasses = "tw-py-3 tw-px-2 tw-text-center tw-uppercase tw-text-[11px]";
  const typeClasses = props.altStyling ? "tw-bg-gray-100 tw-border-b-4 tw-border-b-solid tw-border-gray-200 p-sortable-column-icon--alt" : "tw-bg-emp-blue tw-text-white";

  return `${baseClasses} ${typeClasses}`;
};

const hasErrored = computed(() => (slug, field) => (
  props.cellMetadata[slug]
    && props.cellMetadata[slug][field]
    && props.cellMetadata[slug][field].state === "error"
));
</script>

<style lang="scss">
.p-datatable-tbody {
  > tr:not(.p-datatable-emptymessage):nth-of-type(odd) {
    background-color: #FDFDFD;
  }

  > tr:not(.p-datatable-emptymessage):nth-of-type(even) {
    background-color: #F7F8F8;
  }
}

.p-paginator-page {
  border-radius: 100%;
}

.p-highlight {
  background-color: #6D8AA0;
  color: #ffffff;
}

.p-component {
  font-size: 13px;
}

.p-dropdown-label,
.p-dropdown-trigger {
  color: #000000;
}

.p-paginator {
  color: #000000;
  padding: 10px 0;
}

.p-sortable-column-icon {
  color: #FFFFFF;
  width: 12px;
  margin-left: 0;
}

.p-column-filter-menu-button {
  width: fit-content;
}

.p-column-filter-menu {
  margin-left: 0;
}

.p-datatable-header,
.p-datatable-loading-overlay {
  background-color: transparent;
  padding: 1rem 0;
}

.p-checkbox-box {
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
  border-width: 1px;
}

.p-sortable-column-icon--alt  .p-sortable-column-icon {
  color: #666666;
}

.p-sortable-column-icon--alt.p-highlight  .p-sortable-column-icon {
  color: #FFFFFF;
}

.p-inputtext {
  padding: 0.429rem 0.429rem;
  border-radius: 0.25rem;
}

.p-column-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.p-datatable-loading-overlay {
  display: flex;
  align-items: flex-end;
  height: 230px;
  padding-bottom: 30px;
}

.p-datatable-emptymessage {
  td {
    padding: 0;
  }
}

.p-column-filter-menu-button-active .p-icon {
  color: #2196F3; /* Change to your desired color */
}

.p-frozen-column {
  z-index: 2;
  position: sticky;
  left: 0;
}

</style>
