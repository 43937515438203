import { get, put, patch } from "../../shared/http.js";

export const FETCH_PROJECT_COST_BREAKDOWN = "FETCH_PROJECT_COST_BREAKDOWN";
export const FETCH_SUBCOSTS = "FETCH_SUBCOSTS";
export const FETCH_RD_PROJECTS = "FETCH_RD_PROJECTS";
export const UPSERT_PROJECT_SUBCOSTS = "UPSERT_PROJECT_SUBCOSTS";
export const DEBOUNCED_UPSERT_PROJECT_SUBCOSTS = "DEBOUNCED_UPSERT_PROJECT_SUBCOSTS";
export const COMPLETE = "COMPLETE";
export const CONFIRM_PROJECT_COUNT = "CONFIRM_PROJECT_COUNT";
export const UNLOCK = "UNLOCK";

export const SET_LOADING = "SET_LOADING";
export const SET_GLOBAL_VALIDATION_MESSAGE = "SET_GLOBAL_VALIDATION_MESSAGE";
export const SET_SUBCOSTS = "SET_SUBCOSTS";
export const SET_PROJECT_COST_BREAKDOWN = "SET_PROJECT_COST_BREAKDOWN";
export const SET_RD_PROJECTS = "SET_RD_PROJECTS";
export const TOGGLE_RD_PROJECT = "TOGGLE_RD_PROJECT";
export const SET_SUBCOST_RD_PROJECTS = "SET_SUBCOST_RD_PROJECTS";
export const VALIDATE_SUBCOSTS = "VALIDATE_SUBCOSTS";

export const GET_PROJECT_COST_BREAKDOWN = "GET_PROJECT_COST_BREAKDOWN";
export const GET_GLOBAL_VALIDATION_MESSAGE = "GET_GLOBAL_VALIDATION_MESSAGE";
export const GET_LOADING = "GET_LOADING";
export const GET_SUBCOSTS = "GET_SUBCOSTS";
export const GET_RD_PROJECTS = "GET_RD_PROJECTS";
export const GET_RD_PROJECTS_COUNT = "GET_RD_PROJECTS_COUNT";
export const GET_SUBCOST_RD_PROJECTS = "GET_SUBCOST_RD_PROJECTS";
export const GET_ALL_SUBCOSTS_VALID = "GET_ALL_SUBCOSTS_VALID";
export const GET_SUBCOSTS_VALID = "GET_SUBCOSTS_VALID";
export const GET_REQUIRES_PROJECT_SELECTION = "GET_REQUIRES_PROJECT_SELECTION";

const debouncedFunctions = {};

export const project_cost_breakdown = {
  namespaced: true,
  state: {
    project_cost_breakdown: {},
    globalValidationMessage: null,
    loading: false,
    subcosts: {
      CostsEmployee: [],
      CostsSubcontractor: [],
      CostsConsumable: [],
      CostsOther: []
    },
    rdProjects: [],
    subcostTypes: [
      "CostsEmployee",
      "CostsSubcontractor",
      "CostsConsumable",
      "CostsOther"
    ],
    subcostsValid: {
      CostsEmployee: false,
      CostsSubcontractor: false,
      CostsConsumable: false,
      CostsOther: false
    }
  },
  mutations: {
    [SET_PROJECT_COST_BREAKDOWN]: (state, payload) => state.project_cost_breakdown = payload,
    [SET_GLOBAL_VALIDATION_MESSAGE]: (state, payload) => {
      state.globalValidationMessage = payload;
    },
    [SET_LOADING]: (state, payload) => state.loading = payload,
    [SET_SUBCOSTS]: (state, payload) => {
      state.subcosts[payload.subCostType] = payload.data;
    },
    [SET_RD_PROJECTS]: (state, payload) => state.rdProjects = payload,
    [TOGGLE_RD_PROJECT](state, payload) {
      const subcost = state.subcosts[payload.subcostType].find(subcost => payload.subcostSlug === subcost.slug);

      const index = subcost.rd_projects.findIndex(rdProject => payload.rdProjectSlug === rdProject.slug);

      if (index > -1) {
        subcost.rd_projects.splice(index, 1);
      } else {
        subcost.rd_projects.push({ slug: payload.rdProjectSlug, percent_allocation: 0 });
      }
    },
    [SET_SUBCOST_RD_PROJECTS](state, payload) {
      const subcost = state.subcosts[payload.subcostType].find(subcost => payload.subcostSlug === subcost.slug);
      subcost.rd_projects = payload.rdProjects;
    },
    [VALIDATE_SUBCOSTS]: (state, payload) => {
      state.subcostTypes.forEach(subcostType => {
        let valid = true;

        state.subcosts[subcostType].forEach(subcost => {
          if (subcost.rd_projects.length == 0) {
            valid = false;
            subcost.errors = ["Please select at least one R&D project that this cost is linked to"];
          } else {
            subcost.errors = [];
          }

          const totalPercentAllocation = subcost.rd_projects.reduce((total, rdProject) => total + rdProject.percent_allocation, 0);

          if (totalPercentAllocation !== 100) {
            valid = false;
            subcost.errors.push("While allocating percentages to R&D projects, the total percentage must add up to 100%");
          }

          subcost.rd_projects.forEach(rdProject => {
            if (rdProject.percent_allocation == 0) {
              valid = false;
              subcost.errors.push("Each selected R&D project must have a percentage allocation. If a project is no longer related to the cost, please deselect it");
            }
          });
        });

        state.subcostsValid[subcostType] = valid;
      });
    }
  },
  actions: {
    [FETCH_PROJECT_COST_BREAKDOWN]: async ({ commit, state }, payload) => {
      const response = await get(`/api/v1/project_cost_breakdowns/${payload.slug}`);

      if (response.error) {
        commit(SET_GLOBAL_VALIDATION_MESSAGE, response.message || response.statusText);
        return;
      }

      commit(SET_PROJECT_COST_BREAKDOWN, response);

      return response;
    },
    [FETCH_SUBCOSTS]: async ({ state, commit }, payload) => {
      const response = await get(`/api/costs/${payload.costSlug}/subcosts/${payload.subCostType}/?rd=true`);

      if (response.error == true) {
        commit(SET_GLOBAL_VALIDATION_MESSAGE, "We could not load your costs, please reload the page");
        return;
      }

      const { subcosts } = response;

      const qualifyingSubcosts = subcosts.filter(subcost => subcost.pc_rd > 0);

      commit(SET_SUBCOSTS, { subCostType: payload.subCostType, data: qualifyingSubcosts });
    },

    [FETCH_RD_PROJECTS]: async ({ state, commit }, payload) => {
      const claimPeriodId = state.project_cost_breakdown.claim_period.slug;
      const response = await get(`/api/rd_projects?claim_period_id=${claimPeriodId}`);

      commit(SET_RD_PROJECTS, response);
    },
    [DEBOUNCED_UPSERT_PROJECT_SUBCOSTS]: ({ state, commit }, { subcostType, subcostSlug }) => {
      const key = `debounce-${subcostSlug}`;

      if (!debouncedFunctions[key]) {
        debouncedFunctions[key] = _.debounce(async (subcostType, subcostSlug) => {
          const response = put("/api/v1/project_subcosts/upsert", {
            project_cost_breakdown_id: state.project_cost_breakdown.slug,
            rd_projects: state.subcosts[subcostType].find(subcost => subcost.slug === subcostSlug).rd_projects,
            subcost_type: subcostType,
            subcost_id: subcostSlug
          });

          if (response.error) {
            commit(SET_GLOBAL_VALIDATION_MESSAGE, "We could not save your cost project linking, please reload the page");
          }

          commit(VALIDATE_SUBCOSTS);
        }, 1000);
      }

      debouncedFunctions[key](subcostType, subcostSlug);
    },
    [UPSERT_PROJECT_SUBCOSTS]: async ({ state, dispatch, commit }, payload) => {
      commit(SET_SUBCOST_RD_PROJECTS, {
        subcostType: payload.subcostType,
        subcostSlug: payload.subcostSlug,
        rdProjects: payload.rdProjects
      });

      dispatch(DEBOUNCED_UPSERT_PROJECT_SUBCOSTS, { subcostType: payload.subcostType, subcostSlug: payload.subcostSlug });
    },
    [COMPLETE]: async ({ state, commit }, payload) => {
      const { slug } = state.project_cost_breakdown;
      const response = await patch(`/api/v1/project_cost_breakdowns/${slug}/complete`);

      if (response.error) {
        const errorsInResponse = response.data?.errors || []
        const error = Object.values(errorsInResponse)[0]
          || "We could not complete this section, please reload the page. Contact your claim manager if the problem persists.";

        commit(SET_GLOBAL_VALIDATION_MESSAGE, error);
      } else {
        const claimPeriodSlug = state.project_cost_breakdown.claim_period.slug;
        commit(SET_PROJECT_COST_BREAKDOWN, response);
        window.location.href = `/claim_periods/${claimPeriodSlug}/dashboard`;
      }
    },
    [CONFIRM_PROJECT_COUNT]: async ({ state, commit }, payload) => {
      const { slug } = state.project_cost_breakdown;
      const response = await patch(`/api/v1/project_cost_breakdowns/${slug}/confirm_project_count`);

      if (response.error) {
        commit(SET_GLOBAL_VALIDATION_MESSAGE, "We could not confirm your projects number, please reload the page");
      } else if (response.redirect_url) {
        return response.redirect_url;
      } else {
        return;
      }
    },
    [UNLOCK]: async ({ commit, state }, payload) => {
      const { slug } = state.project_cost_breakdown;
      const response = await patch(`/api/v1/project_cost_breakdowns/${slug}/unlock`);

      if (response.error) {
        const error = response.message || "We could not unlock this section, please reload the page";
        commit(SET_GLOBAL_VALIDATION_MESSAGE, error);
      } else {
        commit(SET_PROJECT_COST_BREAKDOWN, response);
        window.location.href = `/project_cost_breakdowns/${slug}`;
      }
    }
  },
  getters: {
    [GET_PROJECT_COST_BREAKDOWN]: state => state.project_cost_breakdown,
    [GET_GLOBAL_VALIDATION_MESSAGE]: state => state.globalValidationMessage,
    [GET_LOADING]: state => state.loading,
    [GET_SUBCOSTS]: state => subCostType => state.subcosts[subCostType],
    [GET_RD_PROJECTS]: state => state.rdProjects,
    [GET_RD_PROJECTS_COUNT]: state => state.rdProjects.length,
    [GET_SUBCOST_RD_PROJECTS]: state => (subCostType, subCostSlug) => state.subcosts[subCostType].find(subcost => subcost.slug === subCostSlug).rd_projects,
    [GET_ALL_SUBCOSTS_VALID]: state => Object.keys(state.subcostsValid).every(subcostType => state.subcostsValid[subcostType] === true),
    [GET_SUBCOSTS_VALID]: state => subcostType => state.subcostsValid[subcostType],
    [GET_REQUIRES_PROJECT_SELECTION]: state => state.project_cost_breakdown.project_selection_required
  }
};
